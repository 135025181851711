import React, {useState} from 'react';
import {FormField} from './form-field';

export const NewsletterSignup = () => {
    const [email, setEmail] = useState("");
    const isEmailValid = email.trim().length > 0;

    return (
        <form name="newsletter" method="POST">
            <input type="hidden" name="form-name" value="newsletter" />
            <div className="col form-group">
                <FormField type="email" placeholder="Enter Email Address" name="email" onChange={e => setEmail(e.target.value)} value={email} isValid={isEmailValid} />
            </div>
        </form>
    )
}