import React from 'react';
import logo from '../assets/logos/coming-soon.png';
import { HeaderText } from '../components/header-text';
import { NewsletterSignup } from '../components/newsletter-signup';

export const ComingSoon = () => (
  <div className="coming-soon ">
    <div className="h-100 d-flex flex-column justify-content-center align-items-center p-4">
      <div>
        <img src={logo} alt="logo" className="main-logo" />
      </div>

      <div className="d-block d-sm-none w-100 py-3">
        <NewsletterSignup />
      </div>
      <div className="d-none d-sm-block w-50 py-4">
        <NewsletterSignup />
      </div>
    </div>
  </div>
);
